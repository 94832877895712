import { defineStore } from 'pinia'
import { ElNotification } from 'element-plus'

import { PaginationParams } from '@/models/api'
import {
  CarProfileStatus,
  CarProfileUpdateableKeys,
  CarProfileUpdateableParams,
  InsuranceRequestsCreate,
  CarImage,
  PermitImage,
} from '@/models/carProfile'

import vehiclesApi from '@/api/vehicles'
import * as tripsApi from '@/api/trips'

import { parseErrors } from '@/helpers/error-parsers'
import { commonCase } from '@/helpers/strings'
import { getInitialDataModuleState } from '@/helpers/store-state'
import {
  ONGOING_STATUSES,
  PAST_RENTING_STATUSES,
  UPCOMING_STATUSES,
} from '@/constants/trips-statuses-groups'

import getInitialState, {
  getInitialVehicleDetailsState,
  State,
  GetVehiclesParams,
} from './state'

import { GetTripsParams, TripGroup } from '@/store/trips'
export const useVehiclesStore = defineStore({
  id: 'vehicles-store',
  state: getInitialState,
  actions: {
    async getVehicles(params: PaginationParams & GetVehiclesParams) {
      try {
        this.$patch({ listModule: { isLoading: true, error: null } })

        const {
          data: { data: list, meta },
        } = await vehiclesApi.getVehicles(params)

        this.$patch({
          listModule: {
            list,
            meta,
            isLoading: false,
            isInitialLoadingDone: true,
          },
        })
      } catch (e) {
        this.$patch({
          listModule: {
            error: parseErrors(e).error,
            isLoading: false,
            isInitialLoadingDone: true,
          },
        })
      }
    },
    //worked?
    async getVehicleDetails(id: string) {
      try {
        const vehiclesDetails = this.vehiclesDetails

        if (!vehiclesDetails[id]) {
          vehiclesDetails[id] = getInitialVehicleDetailsState()
        }

        this.$patch({
          vehiclesDetails: { [id]: { isLoading: true, error: null } },
        })

        const data = (await vehiclesApi.getVehicleDetails(id)).data.data

        this.$patch({
          vehiclesDetails: { [id]: { isLoading: false, data } },
        })
      } catch (e) {
        const { error } = parseErrors(e)
        this.$patch({
          vehiclesDetails: { [id]: { isLoading: false, error } },
        })
      }
    },

    async updateVehicleStatus(id: string, status: CarProfileStatus) {
      try {
        if (!this.vehiclesDetails[id]?.data) {
          throw new Error('Vehicle data must be received before updating')
        }

        this.$patch({
          vehiclesDetails: {
            [id]: { statusUpdating: { isLoading: true, error: null } },
          },
        })

        await vehiclesApi.updateVehicleStatus(id, status)

        this.$patch((state: State) => {
          const vehicleModule = state.vehiclesDetails[id]

          if (vehicleModule?.data) {
            vehicleModule.data.status = status
            vehicleModule.statusUpdating.isLoading = false
          }

          const vehicleInList = state.listModule.list.find(
            item => item.id === id
          )
          if (vehicleInList) vehicleInList.status = status
        })

        ElNotification({
          type: 'success',
          duration: 6000,
          message: 'Vehicle status was successfully updated.',
        })
      } catch (e) {
        const { error } = parseErrors(e)

        this.$patch({
          vehiclesDetails: {
            [id]: { statusUpdating: { isLoading: false, error } },
          },
        })

        ElNotification({ message: error, duration: 10000, type: 'error' })
      }
    },

    async verifyPermit(id: string, verified: boolean) {
      try {
        if (!this.vehiclesDetails[id]?.data) {
          throw new Error('Vehicle data must be received before updating')
        }

        this.$patch({
          vehiclesDetails: {
            [id]: { permitVerifying: { isLoading: true, error: null } },
          },
        })

        await vehiclesApi.verifyPermit(id, verified)

        this.$patch({
          vehiclesDetails: {
            [id]: {
              data: { ownership_permit_verified: verified },
              permitVerifying: { isLoading: false },
            },
          },
        })

        ElNotification({
          type: 'success',
          duration: 6000,
          message: 'Updated successfully.',
        })
      } catch (e) {
        const { error } = parseErrors(e)

        this.$patch({
          vehiclesDetails: {
            [id]: { permitVerifying: { isLoading: false, error } },
          },
        })

        ElNotification({ type: 'error', duration: 10000, message: error })
      }
    },
    async updateVehicle(id: string, params: CarProfileUpdateableParams) {
      const updatedKeys = Object.keys(params) as CarProfileUpdateableKeys[]

      const keysString = updatedKeys
        .map(key => commonCase(key, true))
        .join(', ')

      try {
        if (!this.vehiclesDetails[id]?.data) {
          throw new Error('Vehicle data must be received before updating')
        }

        this.$patch((state: State) => {
          const updatingModule = state.vehiclesDetails[id]?.updating

          if (!updatingModule) return

          updatingModule.error = null
          updatedKeys.forEach(key => {
            // Mark each key as loading
            if (!updatingModule.loadingKeys.includes(key)) {
              updatingModule.loadingKeys.push(key)
            }

            // Remove from success if was previously added
            const successIndex = updatingModule.successKeys.indexOf(key)

            if (successIndex !== -1) {
              updatingModule.successKeys.splice(successIndex, 1)
            }
          })
        })

        const vehicle = (await vehiclesApi.updateVehicle(id, params)).data.data

        this.$patch((state: State) => {
          const detailsModule = state.vehiclesDetails[id]

          if (!detailsModule?.data) return

          detailsModule.data = { ...detailsModule.data, ...vehicle }

          const updatingModule = detailsModule.updating

          updatedKeys.forEach(key => {
            if (!updatingModule.successKeys.includes(key)) {
              updatingModule.successKeys.push(key)
            }
          })
        })

        ElNotification({
          type: 'success',
          duration: 6000,
          message: `${keysString} value successfully updated.`,
        })
      } catch (e) {
        const { error, validationErrors, validationErrorsRaw } = parseErrors(e)

        this.$patch({
          vehiclesDetails: {
            [id]: { updating: { error: validationErrors || error } },
          },
        })

        const message = `An error while updating ${keysString}: "${validationErrorsRaw?.[0]
          .message || error}"`

        ElNotification({ message, type: 'error', duration: 10000 })
      } finally {
        this.$patch((state: State) => {
          const updatingModule = state.vehiclesDetails[id]?.updating

          if (!updatingModule) return

          updatedKeys.forEach(key => {
            // Remove loading state for key
            const loadingIndex = updatingModule.loadingKeys.indexOf(key)
            if (loadingIndex != -1) {
              updatingModule.loadingKeys.splice(loadingIndex, 1)
            }
          })
        })
      }
    },
    async getVehicleTrips(id: string) {
      try {
        if (!this.vehicleTrips[id]) {
          this.vehicleTrips[id] = getInitialDataModuleState()
        }

        this.$patch({
          vehicleTrips: {
            [id]: { isLoading: true, error: null },
          },
        })

        const params: PaginationParams & GetTripsParams = {
          page: 1,
          perPage: 10,
          sortBy: 'start_date',
          sortDirection: 'desc',
          car_profile_id: id,
        }

        const tripsGroups = await Promise.all([
          tripsApi.getTrips({ ...params, statuses: ONGOING_STATUSES }),
          tripsApi.getTrips({ ...params, statuses: UPCOMING_STATUSES }),
          tripsApi.getTrips({ ...params, statuses: PAST_RENTING_STATUSES }),
        ])

        const [ongoing, upcoming, pastRenting] = tripsGroups.map<TripGroup>(
          ({ data: { meta, data } }) => ({ list: data, total: meta.total })
        )

        this.$patch({
          vehicleTrips: {
            [id]: {
              isLoading: false,
              data: { ongoing, upcoming, pastRenting },
            },
          },
        })
      } catch (e) {
        const { error } = parseErrors(e)

        this.$patch({
          vehicleTrips: {
            [id]: { isLoading: false, error },
          },
        })
      }
    },
    async cancelInsurance(policyNumber: string) {
      try {
        await vehiclesApi.cancelInsurance(policyNumber)
        ElNotification({
          message: 'Insurance successfully canceled',
          type: 'success',
          duration: 10000,
        })
      } catch (e) {
        const { error } = parseErrors(e)
        ElNotification({ message: error, type: 'error', duration: 10000 })
      }
    },

    async createInsurance(
      carProfileId: string,
      payload: InsuranceRequestsCreate
    ) {
      try {
        this.$patch({
          vehiclesDetails: {
            [carProfileId]: { isLoading: true, success: false },
          },
        })

        const {
          data: { data: insurance },
        } = await vehiclesApi.createInsurance(carProfileId, payload)

        this.$patch({
          vehiclesDetails: {
            [carProfileId]: {
              success: true,
              data: {
                insurance_request: insurance,
              },
            },
          },
        })

        ElNotification({
          type: 'success',
          duration: 6000,
          message: `Insurance was successfully created.`,
        })
      } catch (e) {
        const { error } = parseErrors(e)
        ElNotification({ message: error, type: 'error', duration: 10000 })
      } finally {
        this.$patch({
          vehiclesDetails: {
            [carProfileId]: { isLoading: false },
          },
        })
      }
    },

    async uploadImg(carId: string, file: File, type: string) {
      try {
        if (!carId) {
          throw new Error('Car ID is not defined.')
        }

        const response = await vehiclesApi.uploadCarImage(carId, file, type)
        const uploadedImage = response.data.data as CarImage

        if (!uploadedImage) {
          throw new Error(
            `Uploaded image data for type "${type}" not found in response.`
          )
        }

        this.$patch((state: State) => {
          const carDetails = state.vehiclesDetails[carId]
          if (carDetails?.data?.images) {
            carDetails.data.images.push(uploadedImage)
          } else if (carDetails?.data) {
            carDetails.data.images = [uploadedImage]
          }
        })

        ElNotification({
          type: 'success',
          message: `${type} image uploaded successfully.`,
          duration: 6000,
        })
      } catch (error) {
        const parsedError = parseErrors(error)
        const errorMessage =
          typeof parsedError === 'string'
            ? parsedError
            : 'Failed to upload image.'

        ElNotification({
          type: 'error',
          message: errorMessage,
          duration: 10000,
        })

        throw error
      }
    },

    async deleteCarImage(carId: string, imageId: string) {
      try {
        await vehiclesApi.deleteCarImage(carId, imageId)

        this.$patch((state: State) => {
          const carDetails = state.vehiclesDetails[carId]
          if (carDetails?.data?.images) {
            carDetails.data.images = carDetails.data.images.filter(
              image => image.id !== imageId
            )
          }
        })

        ElNotification({
          type: 'success',
          message: 'Image successfully deleted.',
          duration: 6000,
        })
      } catch (error) {
        const { error: parsedError } = parseErrors(error)

        ElNotification({
          type: 'error',
          message: parsedError || 'Failed to delete image.',
          duration: 10000,
        })

        throw error
      }
    },

    async updateInsurance(
      carProfileId: string,
      insuranceId: string,
      payload: Partial<InsuranceRequestsCreate>
    ) {
      const updatedKeys = Object.keys(payload) as CarProfileUpdateableKeys[]

      try {
        this.$patch((state: State) => {
          const updatingModule = state.vehiclesDetails[carProfileId]?.updating

          if (!updatingModule) return

          updatingModule.error = null
          updatedKeys.forEach(key => {
            if (!updatingModule.loadingKeys.includes(key)) {
              updatingModule.loadingKeys.push(key)
            }

            const successIndex = updatingModule.successKeys.indexOf(key)

            if (successIndex !== -1) {
              updatingModule.successKeys.splice(successIndex, 1)
            }
          })
        })

        const {
          data: { data: updated },
        } = await vehiclesApi.updateInsurance(insuranceId, payload)

        this.$patch((state: State) => {
          const detailsModule = state.vehiclesDetails[carProfileId]

          if (!detailsModule?.data) return
          detailsModule.data.insurance_request = updated

          const updatingModule = detailsModule.updating

          updatedKeys.forEach(key => {
            if (!updatingModule.successKeys.includes(key)) {
              updatingModule.successKeys.push(key)
            }
          })
        })

        const [[fieldName]] = Object.entries(payload)
        ElNotification({
          type: 'success',
          duration: 6000,
          message: `${commonCase(fieldName, true)} - was successfully updated.`,
        })
      } catch (e) {
        const { error } = parseErrors(e)
        ElNotification({ message: error, type: 'error', duration: 10000 })
      } finally {
        this.$patch((state: State) => {
          const updatingModule = state.vehiclesDetails[carProfileId]?.updating

          if (!updatingModule) return

          updatedKeys.forEach(key => {
            const loadingIndex = updatingModule.loadingKeys.indexOf(key)
            if (loadingIndex != -1) {
              updatingModule.loadingKeys.splice(loadingIndex, 1)
            }
          })
        })
      }
    },

    // async uploadOwnershipPermit(carId: string, file: File, type: string) {
    //   try {
    //     if (!carId) {
    //       throw new Error('Car ID is not defined.')
    //     }

    //     const response = await vehiclesApi.uploadOwnershipPermit(
    //       carId,
    //       file,
    //       type
    //     )

    //     const { data } = response
    //     if (!data) {
    //       throw new Error('Invalid response from server.')
    //     }

    //     if (!data.data) {
    //       throw new Error('Invalid response from server.')
    //     }

    //     const uploadedImage = data.data

    //     if (!uploadedImage.public_id || !uploadedImage.secure_url) {
    //       console.error('❌ Missing fields in uploaded image:', uploadedImage)
    //       throw new Error('Uploaded image data is incomplete.')
    //     }

    //     const permitImage: PermitImage = {
    //       type: type,
    //       public_id: uploadedImage.public_id,
    //       secure_url: uploadedImage.secure_url,
    //     }
    //     this.$patch((state: State) => {
    //       const carDetails = state.vehiclesDetails[carId]

    //       if (!carDetails?.data) {
    //         console.warn(
    //           '⚠️ carDetails or carDetails.data is missing, skipping update'
    //         )
    //         return
    //       }

    //       carDetails.data = {
    //         ...carDetails.data,
    //         ownership_permit: [
    //           ...(carDetails.data.ownership_permit || []),
    //           permitImage,
    //         ],
    //       }
    //     })

    //     ElNotification({
    //       type: 'success',
    //       message: `${type} ownership permit image uploaded successfully.`,
    //       duration: 6000,
    //     })
    //   } catch (error) {
    //     const parsedError = parseErrors(error)
    //     const errorMessage =
    //       typeof parsedError === 'string'
    //         ? parsedError
    //         : 'Failed to upload ownership permit image.'

    //     ElNotification({
    //       type: 'error',
    //       message: errorMessage,
    //       duration: 10000,
    //     })

    //     throw error
    //   }
    // },

    async uploadOwnershipPermit(carId: string, file: File, type: string) {
      try {
        if (!carId) {
          throw new Error('Car ID is not defined.')
        }

        const response = await vehiclesApi.uploadOwnershipPermit(
          carId,
          file,
          type
        )
        const uploadedImage = response.data.data

        if (!uploadedImage) {
          throw new Error(
            `Uploaded image data for type "${type}" not found in response.`
          )
        }

        const imageWithType = { ...uploadedImage, type }

        this.$patch((state: State) => {
          const carDetails = state.vehiclesDetails[carId]
          if (carDetails?.data?.ownership_permit) {
            carDetails.data.ownership_permit.push(imageWithType)
          } else if (carDetails?.data) {
            carDetails.data.ownership_permit = [imageWithType]
          }
        })

        ElNotification({
          type: 'success',
          message: `${type} ownership permit image uploaded successfully.`,
          duration: 6000,
        })
      } catch (error) {
        const parsedError = parseErrors(error)
        const errorMessage =
          typeof parsedError === 'string'
            ? parsedError
            : 'Failed to upload ownership permit image.'

        ElNotification({
          type: 'error',
          message: errorMessage,
          duration: 10000,
        })

        throw error
      }
    },

    async deleteOwnershipPermit(carId: string, publicId: string) {
      try {
        await vehiclesApi.deleteOwnershipPermit(carId, publicId)

        this.$patch((state: State) => {
          const carDetails = state.vehiclesDetails[carId]
          if (carDetails?.data?.ownership_permit) {
            carDetails.data.ownership_permit = carDetails.data.ownership_permit.filter(
              image => image.public_id !== publicId
            )
          }
        })

        ElNotification({
          type: 'success',
          message: 'Ownership permit image successfully deleted.',
          duration: 6000,
        })
      } catch (error) {
        const { error: parsedError } = parseErrors(error)

        ElNotification({
          type: 'error',
          message: parsedError || 'Failed to delete ownership permit image.',
          duration: 10000,
        })

        throw error
      }
    },
  },
})
